<template>
    
    <div class="container">
        <ToastWidget ref="toast" :auto-hide="true"  />
        <div class="row">
            <div class="col-12">
                <h3><i v-if="challenge.description" class="bi bi-file-text" @click="$emit('showDescription')"> </i><i v-if="challenge.playground" class="bi bi-controller" @click="$emit('showPlayground')"> </i>{{ challenge.title }} <StarRenderer v-if="challenge.data?.bestInstructionCountSolution" :stars="challenge.totalStars()" /> </h3>
                <p>The challenge: {{ challenge.description_short }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-success" id="challenge-run-button" title="Build and Run"
                    @click="runClicked">
                    <i class="bi bi-hammer"></i> &amp; <i class="bi bi-chevron-double-right"></i> Build & Run
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div v-if="challenge.registers_in">
                    <strong>Initial register values:</strong>
                    <Registers :regs="challenge.registers_in" :registerValues="initialRegisters" />
                </div>
                <CodeWidget v-model="code" ref="codeWidget"/>
                <div v-if="challenge.registers_out && finalRegisters">
                    <strong>After execution:</strong>
                    <Registers :regs="challenge.registers_out" :registerValues="finalRegisters" />
                </div>
            </div>
            <div v-if="challenge.show_registers" class="col-sm-12 col-md-6">
                <RegisterWidget :key="emulatorStep" :emulator="emu" />
            </div>
            <div v-if="challenge.show_flags" class="col-sm-12 col-md-6">
                <CPSR :key="emulatorStep" :cpsrFlags="emu.CPSR()" />
            </div>
        </div>
    </div>
</template>

<script>

import CodeWidget from './CodeWidget.vue';
import RegisterWidget from './RegisterWidget.vue';
import ToastWidget from './ToastWidget.vue';
import StarRenderer from './StarRenderer.vue';
import CPSR from './CPSR.vue';
import ArmEmulator from '../emulator.js';
import Registers from './Registers.vue';
import Conditionals from './Conditionals.vue';
import LivePlayground from './LivePlayground.vue';

export default {
    name: 'Challenge',
    components: {
        // Typewriter,
        CodeWidget,
        RegisterWidget,
        ToastWidget,
        StarRenderer,
        Registers,
        CPSR,
        Conditionals,
        LivePlayground
    },
    props: {
        challenge: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            code: this.challenge.template,
            emu: new ArmEmulator(),
            emulatorStep: 0,
            initialRegisters: {},
            finalRegisters: null
        }
    },
    emits: [
        'challengeCompleted',
        'showDescription',
        'showPlayground'
    ],
    mounted() {
        console.log("Challenge mounted")
        this.challenge.load();
        if(this.challenge.data?.lastCode) {
            this.code = this.challenge.data?.lastCode
        }
        // We use the emu here to get the initial register values.
        // Later we might store these in the challenge or something
        this.emu.reset();
        this.challenge.prepare(this.emu);
        if(this.challenge.registers_in) {
            console.log(`regs`)
            console.log(this.emu.getRegisters(this.challenge.registers_in))
            this.initialRegisters = this.emu.getRegisters(this.challenge.registers_in)
        }
    },
    methods: {
        runClicked() {
            // We assemble twice: Once with appendix, once without.
            var pure_assembly = null;
            try {
                pure_assembly = this.emu.assemble(this.code);
                // console.log("Address to line:")
                // console.log(this.emu.assembleInfo(this.code));
                var code = this.code;
                if(this.challenge.code_appendix) {
                    code += "\n" + this.challenge.code_appendix;
                }
                this.emu.load_assembly_program(code)
            } catch {
                this.$refs.toast.showToast("Assembling failed!");
                this.$refs.codeWidget.shakeTextarea();
                return;
            }
            this.emu.reset();
            this.challenge.prepare(this.emu);
            try {
                console.log("EXEC")
                this.emu.run((pc) => {
                    console.log("Step")
                })
            } catch (e) {
                console.log("Exception???")
                console.log(e)
                this.$refs.toast.showToast("Execution failed!");
                this.$refs.codeWidget.shakeTextarea();
            }
            if(this.challenge.registers_out) {
                this.finalRegisters = this.emu.getRegisters(this.challenge.registers_out)
            }
            this.emulatorStep += 1;


            if(this.challenge.checker) {
                console.log("Testing checker")
                console.log(`r0 is ${this.emu.getRegister("R0").toString(16)}`)
                console.log(this.challenge.checker)
                if(this.challenge.checker.final(this.emu)) {
                    
                    console.log("Success!");
                } else {
                    console.log("Failure!");
                }
            // keep compatibility with old checker system
            } else if(this.challenge.check(this.emu)) {
                console.log("YAY")
                const statistics = {
                    instructionCount: pure_assembly.count,
                    executionSteps: 0,
                    code: this.code
                };
                // console.log(this.challenge.data?.bestInstructionCountSolution.instructionCount)
                // console.log(statistics.instructionCount)
                if(!this.challenge.data?.bestInstructionCountSolution || (this.challenge.data?.bestInstructionCountSolution.instructionCount > statistics.instructionCount)) {
                    console.log("New best instruction count!")
                    this.challenge.data.bestInstructionCountSolution = statistics;
                }
                if(!this.challenge.data?.bestExecutionStepsCountSolution || (this.challenge.data?.bestExecutionStepsCountSolution.executionSteps > statistics.executionSteps)) {
                    console.log("New best execution steps!")
                    this.challenge.data.bestExecutionStepsCountSolution = statistics;
                }
                this.challenge.save()

                this.$refs.codeWidget.greenTextarea().then(() => {
                    this.$emit('challengeCompleted', statistics);
                })
                
                // this.statisticsData.instructionCount = this.emu.assembledProgram.count;
                // this.$refs.statsModal.showModal();
            } else {
                console.log("NAY")
                this.$refs.toast.showToast("That's not it :(");
                this.$refs.codeWidget.shakeTextarea();
            }
        }
    },
    watch: {
        code(newCode) {
            console.log("Code watch triggered")
            this.challenge.data.lastCode = newCode;
            this.challenge.save()
        }
    }
};
</script>

<style scoped>
/* Add styles if needed */
</style>