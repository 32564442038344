import {Checker} from './Checker'

export class RegisterValueChecker extends Checker {
    // Provide the expected regitser values here
    constructor(expected_values) {
        super()
        this.expected_values = expected_values
    }

    final(emu) {
        var valid = true;
        for(const register_name in this.expected_values) {
            const value = emu.getRegister(register_name);
            if(value !== this.expected_values[register_name]) {
                console.log(`check failed: ${register_name}: ${value} is not ${ this.expected_values[register_name]}`)
                valid = false;
            }
        }

        return valid;
    }
}
