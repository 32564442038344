<template>
    <div>
        <span v-html="processedText"></span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        speed: {
            type: Number,
            default: 100, // Time in ms between each character
        },
        skip: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentIndex: 0,
            processedText: '',
            inPreBlock: false,
        };
    },
    computed: {
        formattedText() {
            let formattedText = '';
            let insidePreBlock = false;

            for (let i = 0; i < this.text.length; i++) {
                const char = this.text[i];

                if (char === '%' && !insidePreBlock) {
                    formattedText += '<pre style="display: inline;">';
                    insidePreBlock = true;
                    continue;
                }

                if (char === '%' && insidePreBlock) {
                    formattedText += '</pre>';
                    insidePreBlock = false;
                    continue;
                }

                if (char === '\n') {
                    formattedText += '<br />';
                    continue;
                }

                formattedText += `<span class="char-${i}" style="visibility: hidden;">${char}</span>`;
            }

            return formattedText;
        },
    },
    methods: {
        typeCharacter() {
            const currentChar = this.text[this.currentIndex];

            if (currentChar === '^') {
                if(!this.skip) {
                    setTimeout(() => {
                        this.currentIndex++;
                        this.typeCharacter();
                    }, 1000); // 100ms pause for ^
                } else {
                    this.typeCharacter();
                }
            } else if (currentChar === '%' && !this.inPreBlock) {
                this.inPreBlock = true;
                this.currentIndex++;
                this.typeCharacter(); // Immediately continue to the next character
            } else if (currentChar === '%' && this.inPreBlock) {
                this.inPreBlock = false;
                this.currentIndex++;
                this.typeCharacter(); // Immediately continue to the next character
            } else {
                const charSpan = this.$el.querySelectorAll(`.char-${this.currentIndex}`)[0];
                if (charSpan) {
                    charSpan.style.visibility = 'visible';
                }
                this.currentIndex++;
                if (this.currentIndex < this.text.length) {
                    if(!this.skip) {
                        setTimeout(this.typeCharacter, this.speed);
                    } else {
                        this.typeCharacter();
                    }
                }
            }
        },
        startTyping() {
            this.currentIndex = 0;
            this.inPreBlock = false;
            this.processedText = this.formattedText;
            this.$nextTick(() => {
                this.typeCharacter();
            });
        },
    },
    watch: {
        text() {
            this.startTyping(); // Restart the typing effect when the text changes
        },
    },
    mounted() {
        this.startTyping(); // Start the typing effect when the component is mounted
    },
};
</script>

<style scoped>
.char {
    visibility: hidden;
}

pre {
    display: inline;
    margin: 0;
    white-space: pre-wrap;
}
</style>