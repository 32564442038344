// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Add styles if needed */
`, "",{"version":3,"sources":["webpack://./src/components/ChallengeOverview.vue"],"names":[],"mappings":";AA4CA,yBAAyB","sourcesContent":["<template>\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-12\">\n                <h3>{{ challenge.title }}</h3>\n                <h5>The challenge:</h5>\n                <p>\n                    <Typewriter :text=\"challenge.description\" :speed=\"1\" :skip=\"this.challenge.data.descriptionRead\"/>\n                    <button class=\"btn btn-primary\" @click=\"next\">Okay, let's go!</button>\n                </p>\n            </div>\n        </div>\n    </div>\n</template>\n\n<script>\nimport Typewriter from './Typewriter.vue';\n\nexport default {\n    name: 'ChallengeOverview',\n    components: {\n        Typewriter,\n    },\n    props: {\n        challenge: {\n            type: Object,\n            required: true,\n        },\n    },\n    emits: [\n        'next'\n    ],\n    methods: {\n        next() {\n            this.challenge.data.descriptionRead = true;\n            this.challenge.save()\n            // Emit an event called 'start' to the parent\n            this.$emit('next');\n        },\n    },\n};\n</script>\n\n<style scoped>\n/* Add styles if needed */\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
