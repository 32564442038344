export class ChallengeBase {
    // Give the challenge a short title
    title = ""

    // This is a longform description of the challenge and can be multiline.
    // Put code in between % signs, i.e. %mov r0, #1%
    // Use ^ to pause the typewriter briefly for 100ms
    // No other format is supported.
    description = ``
    
    // A very short description that is shown to the user while solving the challenge.
    // It should contain ALL information required to solve the challenge without any
    // manual or description of instructions etc - that's what the long description is for.
    description_short = ""

    // This will be the initial value of the code-field
    template = "mov r0, #0"

    // Whether to show the current condition flags after the execution
    show_flags = false

    // The register values to show above the code-widget and with the values *before*
    // the challenge is run
    // i.e.: registers_out = ["R0"]
    registers_in = null
    // The registers to show after running the code
    // i.e.: registers_out = ["R0"]
    registers_out = null
    // Default register values when the emulator starts
    // i.e.: register_values = {"R0":  0x1234}
    register_values = []
    // Code that gets invisibly appended to the user's solution
    code_appendix = null

    // A list of new instructions taught as part of this challenge
    new_instrucitons = []

    slug() {
        return 'challenge-data-' + this.title;
    }

    instructionCountStars(instructionCount) {
        if (instructionCount <= this.score["3"].instructionCount) return 3;
        if (instructionCount <= this.score["2"].instructionCount) return 2;
        return 1;
    }
    executionStepsStars(executionSteps) {
        if (executionSteps <= this.score["3"].executionSteps) return 3;
        if (executionSteps <= this.score["2"].executionSteps) return 2;
        return 1;
    };

    totalStars() {
        // One check should be enough, but you never know!
        if(!this.data?.bestExecutionStepsCountSolution || !this.data?.bestInstructionCountSolution) {
            return null;
        }

        const ics = this.instructionCountStars(this.data?.bestInstructionCountSolution.instructionCount);
        const ess = this.executionStepsStars(this.data?.bestExecutionStepsCountSolution.executionSteps);
        console.log(`ics stars ${ics} ess stars ${ess}`)
        return (ics + ess) / 2;
    }

    calculateChallengeScore(stats) {
        const score = {
            instructionCountStars: 0,
            executionStepsStars: 0,
            totalStars: 0
        };
        // // Compute stars for instruction count
        const instructionCountStars = (challenge, instructionCount) => {
            if (instructionCount <= challenge.score["3"].instructionCount) return 3;
            if (instructionCount <= challenge.score["2"].instructionCount) return 2;
            return 1;
        }
        // // Compute stars for execution steps
        const executionStepsStars = (challenge, executionSteps) => {
            if (executionSteps <= challenge.score["3"].executionSteps) return 3;
            if (executionSteps <= challenge.score["2"].executionSteps) return 2;
            return 1;
        };

        score.instructionCountStars = instructionCountStars(this, stats.instructionCount);
        score.executionStepsStars = executionStepsStars(this, stats.executionSteps);
        const total = (score.instructionCountStars + score.executionStepsStars) / 2;
        score.totalStars = total;
        return score;
    }

    load() {
        console.log("Calling challenge base loading")
        const lsdata = localStorage.getItem(this.slug())
        if(lsdata) {
            this.data = JSON.parse(lsdata);
        }
        if(!this.data) {
            this.data = {
                descriptionRead: false,
                bestInstructionCountSolution: undefined,
                bestExecutionStepsCountSolution: undefined,
                lastCode: undefined,
                descriptionRead: false
            }
        }
    }

    save() {
        const challengeData = {
            bestInstructionCountSolution: {
                code: `mov r0, #1`,
                instructionCount: 3,
                executionSteps: 5
            },
            bestExecutionStepsCountSolution: {
                code: `mov r0, #1`,
                instructionCount: 3,
                executionSteps: 5
            },
            lastCode: `mov r0, #123`,
            descriptionRead: false
        }

        localStorage.setItem(this.slug(), JSON.stringify(this.data));
    }
}


// calculateChallengeScore(stats) {
//     const score = {
//         instructionCountStars: 0,
//         executionStepsStars: 0,
//         totalStars: 0
//     };
//     // // Compute stars for instruction count
//     const instructionCountStars = (challenge, instructionCount) => {
//         if (instructionCount <= challenge.score["3"].instructionCount) return 3;
//         if (instructionCount <= challenge.score["2"].instructionCount) return 2;
//         return 1;
//     }
//     // // Compute stars for execution steps
//     const executionStepsStars = (challenge, executionSteps) => {
//         if (executionSteps <= challenge.score["3"].executionSteps) return 3;
//         if (executionSteps <= challenge.score["2"].executionSteps) return 2;
//         return 1;
//     };

//     score.instructionCountStars = instructionCountStars(this.challenge, stats.instructionCount);
//     score.executionStepsStars = executionStepsStars(this.challenge, stats.executionSteps);
//     const total = (score.instructionCountStars + score.executionStepsStars) / 2;
//     score.totalStars = total;
// },

// storeChallengeData() {
    
// },

