<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3>{{ challenge.title }}</h3>
                <h5>The challenge:</h5>
                <p>
                    <Typewriter :text="challenge.description" :speed="1" :skip="this.challenge.data.descriptionRead"/>
                    <button class="btn btn-primary" @click="next">Okay, let's go!</button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Typewriter from './Typewriter.vue';

export default {
    name: 'ChallengeOverview',
    components: {
        Typewriter,
    },
    props: {
        challenge: {
            type: Object,
            required: true,
        },
    },
    emits: [
        'next'
    ],
    methods: {
        next() {
            this.challenge.data.descriptionRead = true;
            this.challenge.save()
            // Emit an event called 'start' to the parent
            this.$emit('next');
        },
    },
};
</script>

<style scoped>
/* Add styles if needed */
</style>