import { ChallengeBase } from "./Challenge"

export class BitwiseShifting extends ChallengeBase {
    title = "Bitwise Shifting with LSL and LSR"
    description = `Now that you know about logical operations, let's move on to bit shifting!

LSL (Logical Shift Left)
This shifts the bits of a register to the left by a specified amount, filling the empty spaces on the right with zeros. It is often used for multiplying by powers of two.
  
LSR (Logical Shift Right)
This shifts the bits to the right, filling the left with zeros. It's useful for dividing by powers of two.

Here's an example:
%
mov r0, #4        // r0 = 00000000 00000000 00000000 00000100
lsl r0, r0, #1    // r0 = 00000000 00000000 00000000 00001000 (4 * 2 = 8)
lsr r1, r0, #2    // r1 = 00000000 00000000 00000000 00000010 (8 / 4 = 2)
%

Let's give it a try!
`

    description_short = `Shift r0 left (LSL) by 2, and r1 right (LSR) by 1! Then shift r2 to the right by r1!`
    show_flags = false

    prepare(emu) {
        console.log("Preparing challenge...");
        emu.setRegister("R0", 0b00000011) // initial value for shifting left
        emu.setRegister("R1", 0b00001100) // initial value for shifting right
        emu.setRegister("R2", 0b0100000) // initial value for shifting right
    }

    check(emu) {
        const r0 = emu.getRegister("R0");
        const r1 = emu.getRegister("R1");
        const r2 = emu.getRegister("R2");

        // Check if R0 has been shifted left by 2 positions
        const r0_expected = 0b00001100; // 0b00000011 << 2 = 0b00001100

        // Check if R1 has been shifted right by 1 position
        const r1_expected = 0b00000110; // 0b00001100 >> 1 = 0b00000110

        // Check if R2 equals r0 + r1
        const r2_expected = (0b0100000 >> r1_expected);

        return (r0 == r0_expected) && (r1 == r1_expected) && (r2 == r2_expected);
    }

    template = ``

    registers_out = ["R0", "R1", "R2"]

    score = {
        "3": {
            instructionCount: 3,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}