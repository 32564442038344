<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-5">
                <h4>cmp instruction</h4>
                <p>The <span style="font-family: monospace;">cmp</span> instruction can be used to <b>c</b>o<b>mp</b>are two registers. It works by substracting the second operand from the first operand, and then updating the flags in the CPSR.<br/><br/>We can then use conditional execution to execute code depending on the flags!</p>
            </div>
            <div class="col-3">
                <h4>Try it</h4>
                <p style="font-family: monospace; white-space: pre-wrap;">
                    <span v-for="(part, index) in templateParts" :key="index">
                        <template v-if="part === '<IMMEDIATE>'">
                            <input class="assembly_input" value="0" type="text" v-model="immediateValues[index]" @input="validateInput(index)"
                                style="width: 50px;" placeholder="0" />
                        </template>
                        <template v-else>
                            {{ part }}
                        </template>
                    </span>
                </p>
            </div> <!-- col-4 -->
        </div>
        <div class="row">
            <div class="col-4">
                <CPSR :key="emulatorStep" :cpsrFlags="emu.CPSR()" :configuredFlags="playground.flags" />
            </div> <!-- col-4 -->
            <div class="col-4">
                <Conditionals key="emulatorStep" :cpsrFlags="emu.CPSR()" :configuredConditions="playground.conditionals" />
            </div> <!-- col-4 -->
            <div>
                <button class="btn btn-primary" @click="next">Okay, let's go!</button>
            </div>
            
        </div>
    </div>
</template>

<script>
import ArmEmulator from '../emulator.js';
import CPSR from './CPSR.vue';
import Conditionals from './Conditionals.vue';
export default {
    name: 'LivePlayground',
    components: {
        CPSR,
        Conditionals
    },
    props: {
        playground: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            emu: new ArmEmulator(),
            templateParts: [], // Array to store template parts
            immediateValues: {}, // Object to store values for each <IMMEDIATE>
            generatedCode: "", // To store the final generated code
            emulatorStep: 0,
        };
    },
    mounted() {
        this.splitTemplate(); // Split the template initially
        for(var i = 0; i < this.playground.initial_values.length; i++) {
            console.log("loop")
            // the index here is TEMPLATE_PART(0) + IMMEDIATE(1) + TEMPLATE_PART(2) + IMMEDIATE(3)
            this.immediateValues[(i * 2) + 1] = this.playground.initial_values[i];
        }
        this.generateCode()
    },
    emits: [
        'next'
    ],
    methods: {
        next() {
            // this.challenge.data.descriptionRead = true;
            // this.challenge.save()
            // Emit an event called 'start' to the parent
            this.$emit('next');
        },

        // Split the template into parts (text and <IMMEDIATE> blocks)
        splitTemplate() {
            this.templateParts = this.playground.assembly_template.split(/(<IMMEDIATE>)/g);
        },

        // Method to validate input to allow only alphanumeric characters
        validateInput(index) {
            console.log("validate " + index)
            const value = this.immediateValues[index];
            // Allow only alphanumeric characters
            if (/[^a-zA-Z0-9\-]/.test(value)) {
                this.immediateValues[index] = value.replace(/[^a-zA-Z0-9]/g, '');
            }

            try {
                this.generateCode()
            } catch {}
        },

        // Method to generate the final code
        generateCode() {
            let code = '';

            // Combine template parts and replace <IMMEDIATE> with values
            this.templateParts.forEach((part, index) => {
                if (part === '<IMMEDIATE>') {
                    code += this.immediateValues[index] || '<IMMEDIATE>';
                } else {
                    code += part;
                }
            });

            // Update the final code
            this.generatedCode = code;
            this.emu.load_assembly_program(code)
            this.emu.run()
            this.emulatorStep += 1;

        },
    },
};
</script>

<style scoped>
/* Add any custom styles here */
th,
td {
    text-align: center;
    cursor: pointer;
}
</style>