import { ChallengeBase } from "./Challenge"
import { RegisterValueChecker } from '../checkers/RegisterValueChecker'
export class Add extends ChallengeBase {
    title = "add - assembly addition"
    description = `Arm also comes with a few math instructions - for example for addition:

%add r0, r1, r2%

This will perform %r0 = r1 + r2%, adding r1 and r2 together into r0.

There is also a short hand if you just want to add something to the first operand (r0 in our case):

%add r0, r1%

This will simply add r1 to r0, and is equivalent to

%add r0, r0, r1%

Let's give it a try!
`

    description_short = `Add r1, r2 and r3 together into r0!`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R1", 0x123)
        emu.setRegister("R2", 0x234)
        emu.setRegister("R3", 0x456)
    }

    checker = new RegisterValueChecker({
        "R0": 0x123 + 0x234 + 0x456
    })

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x123 + 0x234 + 0x456) {
            return true;
        }
        return false;
    }

    template = `add r0, r0, r0`

    registers_in = ["R1", "R2", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
