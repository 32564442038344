import { ChallengeBase } from "./Challenge"

export class BranchExchange extends ChallengeBase {
    title = "BX - Branch Exchange"
    new_instructions = ["BX"]
    description = `We used the B - or branch - instruction so far, and learned that it does a *relative* branch: It performs a branch relative to our current program counter, limiting how - and how far - you can jump.

The BX - or Branch Exchange - instruction allows us to provide an address in a regstier that we branch to, so for example:

%
mov r0, #0x4000
BX r0
%

Will branch to the address 0x4000!

Now you might wonder why it's called Branch *Exchange*: This is because with BX we can also change the active instruction set of the CPU, from 32-bit Arm to Thumb...

But don't worry about that yet, we will cover Thumb later!

Now lets try to branch to the address 0x4000!
`

    description_short = `Branch Exchange - BX - to the address 0x4000!`
    prepare(emu) {
        // emu.load_pure_assembly_program(0x4000, `mov r0, #2342
        //     B end`);
        // this.hookCode.bind(this);
        // emu.u.hook_add(uc.HOOK_CODE, this.hookCode, {
        //     "emu": "foo"
        // });
        // emu.u.hook_add(uc.HOOK_BLOCK, this.hookCode, {
        //     "emu": "foo"
        // });
    }


    // user_callback(handle, addr_lo, addr_hi, size, user_data);
    hookCode(uc, addr_lo, addr_hi, size, userdata) {
        console.log(`hook code Data: ${uc} addr_lo ${addr_lo} addr_hi ${addr_hi} size ${size} userdata ${userdata}`)
        // console.log("HOOOK")
        // console.log(this);
        // console.log(user_data)
        console.log(`2Executed instruction at 0x${address.toString(16)}, size: ${size}`);
    }

    check(emu) {
        console.log("Checking for success")
        if (emu.getRegister("R0") == 2342) {
            return true;
        }
        return false;
    }

    template = `mov r0, #0`

    code_appendix = `end:`


    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
