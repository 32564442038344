import { ChallengeBase } from "./Challenge"

export class Playground extends ChallengeBase {
    title = "Playground"
    description = null

    description_short = `Do whatever you want - this is just for experimentation during development.`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R1", 0x123)
        emu.setRegister("R2", 0x234)
        emu.setRegister("R3", 0x456)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x123 + 0x234 + 0x456) {
            return true;
        }
        return false;
    }

    template = `add r0, r0, r0`

    registers_in = ["R0", "R1", "R2", "R3"]
    registers_out = ["R0", "R1", "R2", "R3"]
    show_flags = true
    

    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
