import { ChallengeBase } from "./Challenge"

export class Compare extends ChallengeBase {
    title = "cmp-ring values..."
    description = `So far we only wrote completely linear code. Let's talk about changing up the execution flow!

We can compare the value of two registers using the %cmp% - or compare - instruction:

%
mov r0, #5
mov r1, #2
cmp r0, r1
%

In this case, we are comparing r0 (5) with r1 (2).

Now the way compare works is that it substracts the second operand from the first operand, so cmp r0, r1 is equal to r0 - r1 - and then it stores a couple of properties of the results:

For example if the result of the substraction is zero, then the "Z" or "Zero" flag in something called the CPSR - current program status register, we will learn more about that one later - is set to 1, and otherwise to 0.

If that Z flag is set (1), then the two values must be equal - and if the Z flag is unset (0), then the two values are not equal.

And this is exactly how conditionals work on Arm: If we want to take a branch ("jump to a piece of code") if the compared registers are identical, then we can use the BEQ - branch if equal - instruction, which jumps to the label/relative address specified if the compare was equal!

We can also do the opposite: BNE - branch if not equal - to jump somewhere if the values are not identical!

Heres a small piece of example code, it compares r0 and r1, and branches to r0_and_r1_are_equal if r0 and r1 are equal, and to r0_and_r1_are_not_equal if they are not equal.

%
mov r0, #5
mov r1, #2
cmp r0, r1
BEQ r0_and_r1_are_equal
BNE r0_and_r1_are_not_equal

r0_and_r1_are_equal:
mov r2, #2
B end

r0_and_r1_are_not_equal:
mov r2, #1

end:

%


Now lets give this a try, and see how the Z flag changes as we play with the values.
`
    show_flags = true
    description_short = `Compare r0 and r1. If they are equal, branch to equal, otherwise branch to not_equal.`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R0", 1)
        emu.setRegister("R1", 2)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 1) {
            return true;
        }
        return false;
    }


    code_appendix = `
equal:
    mov r0, #0
    B end
not_equal:
    mov r0, #1
end:
`

    template = ``

    // registers_in = ["R1", "R2", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 3,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []



    playground = {
        title: "cmp instruction",
        description: `The <span style="font-family: monospace;">cmp</span> instruction can be used to <b>c</b>o<b>mp</b>are two registers. It works by substracting the second operand from the first operand, and then updating the flags in the CPSR.<br/><br/>We can then use conditional execution to execute code depending on the flags!`,
        assembly_template: `mov r0, #<IMMEDIATE>
mov r1, #<IMMEDIATE>
cmp r0, r1`,
        initial_values: [1, 2],
        registers: [],
        flags: ["Z"],
        conditionals: ["eq", "ne"]
    }
}
