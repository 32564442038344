<template>
    <div>
        <h4>Conditionals</h4>
        <table>
            <tr v-for="(condition, index) in configuredConditions" :key="'condition-' + index"
                :class="isConditionTrue(condition) ? 'conditional_true' : 'conditional_false'">
                <th>{{ condition.toUpperCase() }}</th>
                <td>{{ flagDescriptions[condition] }}</td>
                <td>{{ isConditionTrue(condition) ? 1 : 0 }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import * as bootstrap from 'bootstrap';

export default {
    name: "CPSR",
    props: {
        configuredConditions: {
            type: Array,
            default: () => ["eq", "ne", "gt", "lt", "ge", "le"]
        },
        cpsrFlags: {
            type: Object,
            required: true
        }
    },
    mounted() {
        console.log("CPSR ")
        console.log(this.configuredConditions)
    },
    computed: {
        // Equal
        eq() {
            return this.cpsrFlags.Z;
        },
        // Not-equal
        ne() {
            return !this.cpsrFlags.Z;
        },
        // Unsigned higher/carry set
        cs() {
            return this.cpsrFlags.C;
        },
        hs() {
            return this.cpsrFlags.C;
        },
        // Unsigned lower/carry clear
        cc() {
            return !this.cpsrFlags.C;
        },
        // Minus/Negative
        mi() {
            return this.cpsrFlags.N;
        },
        // Positive/Plus
        pl() {
            return !this.cpsrFlags.N;
        },
        // Signed overflow/V set
        vs() {
            return this.cpsrFlags.V;
        },
        // No signed overflow/V clear
        vc() {
            return !this.cpsrFlags.V;
        },
        // Unsigned higher
        hi() {
            return this.cpsrFlags.C && !this.cpsrFlags.Z;
        },
        // Unsigned lower/same
        ls() {
            return !this.cpsrFlags.C && this.cpsrFlags.Z;
        },
        // Signed greater than/equal
        ge() {
            return this.cpsrFlags.N === this.cpsrFlags.V;
        },
        // Signed less than
        lt() {
            return this.cpsrFlags.N !== this.cpsrFlags.V;
        },
        // Signed greater than
        gt() {
            return !this.cpsrFlags.Z && this.cpsrFlags.N === this.cpsrFlags.V;
        },
        // Signed less/equal
        le() {
            return this.cpsrFlags.Z || this.cpsrFlags.N !== this.cpsrFlags.V;
        }
    },
    data() {
        return {
            flagDescriptions: {
                eq: "Equal",
                ne: "Not equal",
                cs: "Unsigned higher/carry set",
                hs: "Unsigned higher/carry set",
                mi: "Negative/Minus",
                pl: "Positive or zero/Plus",
                vs: "Signed overflow/V set",
                vc: "No signed overflow/V clear",
                hi: "Unsigned higher",
                ls: "Unsigned lower/same",
                ge: "Signed greater than/equal",
                lt: "Signed less than",
                gt: "Signed greater than",
                le: "Signed less/equal"
            }
        };
    },
    methods: {
        // Method to check if a condition is true
        isConditionTrue(condition) {
            // Dynamically check if the computed property (condition) is true
            return this[condition];
        }
    }
};
</script>

<style scoped>
th,
td {
    text-align: left;
    cursor: pointer;
    padding-right: 9px;
}

/* Add conditional styles */
.conditional_true {
    color: lightgreen;
}

.conditional_false {
    color: lightcoral;
}
</style>